import React from 'react';
import {EmbedLayout} from "../../containers/EmbedLayout";
import {PageState} from "../../containers/PageState";
import {ContactUs} from "../../sections/ContactUs";
import {H1} from "../../components/NativeComponents";

export type EmbedContactProps = {} & PageState;
export default function EmbedContact({...props}: EmbedContactProps) {
    return <EmbedLayout {...props} meta={{title: 'Contact Us'}}>
        <ContactUs TitleComponent={H1}/>
    </EmbedLayout>;
}
